body{
    font-family: 'Merriweather', serif;
}

.main-nav-active{
    color:#6597D7;
}

.main-nav:hover{
    color:#1E385C;
}

h1{
    background: linear-gradient(to right,rgb(30,56,92),rgb(101,151,215));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.padtopandbottom{
    max-height: 900px;  
}

.size{  
    border-radius: 5%;
}

.Product{
    
    background-color: white;
    text-align: center;
    font-size: 22px;
}

.announcement_img{
    height: 300px; 
    object-fit: cover;
    justify-content: center;
    padding:'5% 7% 5% 7%';
}



.announce_des{
    height: 110px;
}



.buztag{   
    margin-left: auto;
    margin-right: auto;
    width: 220px;    
    border-radius: 5%;
}

.down{
    width: 180px;
    margin: 2%;
}

.foot{
    margin: 2% auto;
}

.foot-img{
    padding: 1.5%;
    color: black;
    font-size: larger;
}

.cim{
    background-image: url('./images/tickmark.avif');
    background-position: right;
    padding-right:30px;
    background-size: 20px;
    background-repeat: no-repeat;
}

.pad{
    margin: 0% 10%;
}

.makeitcentre{
    display: flex;
    justify-content: center;
    align-items: center;
}

div.fullscreen-image {
    position: relative;
    width: 100%;
    height: 100%;
       
  }

.con{
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-wrap: wrap;
    padding: 40px auto;
}

.con .concard{
    position: relative;
    min-width: 500px;
    min-height: 800px;
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
                inset -5px -5px 5px rgba(255,255,255,0.5),
                5px 5px 5px rgba(0,0,0,0.05),
                -5px -5px 5px rgba(255,255,255,0.5);
    border-radius: 15px;
    margin: 30px;
}


.con .concard .conbox{
    position:absolute;
    top:20px;
    left:20px;
    right: 20px;
    bottom: 20px;
    /* background: #ebf5fc; */
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.con .concard:hover .conbox{
    transform: translateY(-50px);
    box-shadow: 0 10px 40px rgba(0,0,0,0.4);
    background: #1E385C;
    
    color: #fff;
}

.con .concard .conbox .concontent{
    padding: 20px;
    text-align: center;
}

.con .concard .conbox .concontent h3{
    font-size: 1.8em;
    color: #777;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 10px;
}

.con .concard .conbox .concontent p{
    font-size: 1em;
    font-weight: 300;
    color: #777;
    z-index: 1;
    transition: 0.5s;
}

.con .concard:hover .conbox .concontent p{
    color: #fff;
}

.con .concard:hover .conbox .concontent h3{
    color: #fff;
}

.con .concard .conbox .concontent a{
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    
    background:#1E385C;
    margin-top: 15px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.con .concard:hover .conbox .concontent a{
    background:#1E385C;
    background:#6597D7;
}
